import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, OnDestroy {
  message?: string;
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.handleAccountStuff();
      });

    if (!this.isIframe) {
      this.handleAccountStuff();
    }
  }

  handleAccountStuff() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length === 0) {
      this.router.navigate(['/authentication/login'])
      // Show login screen
      this.message = 'No account, show login page';
      return;
    }

    if (accounts.length > 1) {
      const pickedAccount = this.msalService.instance.getActiveAccount();
      if (pickedAccount) {
        this.message = 'One account selected';
        this.router.navigate(['/home']);
      } else {
        this.message = 'More accounts, show account selection';
        this.router.navigate(['/authentication/switch-accounts']);
      }

      return;
    }

    this.message = 'Only one account';
    this.router.navigate(['/home']);
  }

}
