import { Component, OnInit } from '@angular/core';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.css']
})
export class FullscreenComponent implements OnInit {

  constructor(
    private appInsights: AppInsightsService
  ) { }

  ngOnInit(): void {
  }

}
