import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { AccountService, UserViewModel } from '../../services/api.services';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public user?: UserViewModel;
  public loading: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  title = 'app';
  constructor(
    private accountService: AccountService,
    private msalBroadcastService: MsalBroadcastService,
    private appInsights: AppInsightsService
  ) {

  }


  ngOnInit(): void {
    this.loadUser();
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroying$),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        delay(50)
    ).subscribe((result) => {
        console.log('Login success:', result);
        this.loadUser();
      })
  }

  private loadUser(): Promise<void> {
    return this.accountService.me()
      .toPromise()
      .then((user) => {
        this.user = user;
        this.loading = false;
        console.debug('User data', user);
        this.appInsights.setUserId(user.userId, user.tenantId);
      })
      .catch((err) => {
        console.warn('User login error', err);
        this.loading = false;
      })
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
