import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';


export const GRAPH_BASE_URL = new InjectionToken<string>('GRAPH_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(GRAPH_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https://graph.microsoft.com/v1.0/";
  }



  protected getCollection<T>(url: string): Observable<collectionResponse<T> | undefined> {
    return this.get<collectionResponse<T>>(url);
  }

  public getUserById(id: string) {
    return this.get<any>(`users/${id}`);
  }

  public getGroupById(id: string) {
    return this.get<any>(`groups/${id}`);
  }

  private defaultGetOptions(): any {
    return {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
  }

  get<T>(url: string): Observable<T | null> {
    const url_ = `${this.baseUrl}${url}`;
    return this.http.request("get", url_, this.defaultGetOptions()).pipe(mergeMap((response_: any) => {
      return this.processGet<T>(response_);
    })).pipe(catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGet<T>(<any>response_);
        } catch (e) {
          return <Observable<T | null>><any>throwError(e);
        }
      } else
        return <Observable<T | null>><any>throwError(response_);
    }));
  }

  protected processGet<T>(response: HttpResponseBase): Observable<T | null> {
    console.log(response);
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {};
    if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        result200 = _responseText === "" ? null : <T>JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<T | null>(<any>null);
  }
}

interface collectionResponse<T> {
  value: Array<T>;
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
  if (result !== null && result !== undefined)
    return throwError(result);
  else
    return throwError(new ApiException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
