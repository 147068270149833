<router-outlet></router-outlet>
  <!--<ng-container *ngIf="!loading; else loadingScreen">
    <app-nav-menu [user]="user"></app-nav-menu>
    <div class="container-fluid" id="mainContainer">
      <div class="row">
        <app-sidebar [user]="user"></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingScreen>
    <p>Loading...</p>
  </ng-template>-->
