import { Component, OnDestroy, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { delay, filter, first, map, takeUntil } from 'rxjs/operators';
import { UserViewModel } from './services/api.services';
import { CachedAccountService } from './services/cached-account.service';
import { CustomizationService } from './services/customization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public user?: UserViewModel;
  public loading: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  title = 'Schoolsync';
  constructor(
    private accountService: CachedAccountService,
    private msalBroadcastService: MsalBroadcastService,
    private customizationService: CustomizationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,

  ) {
    this.loading = false;
    this.router.events.pipe(
      takeUntil(this._destroying$),
      filter(event => event instanceof NavigationEnd),
      map((event) => {
        //console.log('AppModule NavEnd', event, this.activatedRoute)

        const childRoute = this.activatedRoute.firstChild?.firstChild?.firstChild
        //console.log('Route', childRoute);
        if (childRoute && childRoute.snapshot.data && childRoute.snapshot.data['title']) {
          return childRoute.snapshot.data['title'] + ` - ${this.title}`;
        }
        return this.title;
      })
      
    ).subscribe((title: string) => {
      //console.log('Changing title to', title);
      this.titleService.setTitle(title);
    })
  }
  ngOnInit(): void {
    this.customizationService.customizations$.pipe(takeUntil(this._destroying$), first()).subscribe(data => {
      this.title = data.name;
    });    
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
