
<div class="container-fluid" style="height: 100vh">
  <div class="row h-100">
    <div class="col"></div>
    <div class="col">
      <div class="card" style="margin-top: 20%">
        <h5 class="card-header">EducationHUB Syncservice</h5>
        <div class="card-body text-center">
          <p>Checking account</p>
          <div class="w-100">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
