<nav class="col-md-2 d-none d-md-block bg-secondry sidebar" [ngStyle]="{ 'background-image': 'linear-gradient(45deg, rgba(232,76,54,0.1),rgba(232,76,54,0.6)), url(' + background.image + ')'}">
  <div class="sidebar-sticky">
    <div class="justify-content-center d-flex" *ngIf="user && user.tenant && !user.tenant.name && !user.isTenantManager">
      <a class="btn btn-lg btn-primary" [routerLink]="['/demo']">Request demo</a>
    </div>
    
    <ul class="nav flex-column" *ngIf="user && user.tenant && user.tenant.name">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <span class="text-right"><fa-icon [icon]="faTachometerAlt"></fa-icon></span>
          Dashboard
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/users']" [queryParams]="{ synced: false, unavailable: false }">
          <span><fa-icon [icon]="faUser"></fa-icon></span>
          Users
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/groups']" routerLinkActive="active">
          <span><fa-icon [icon]="faUsers"></fa-icon></span>
          Groups
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/groups/archive-teams']" routerLinkActive="active">
          <span><fa-icon [icon]="faUsers"></fa-icon></span>
          Archive teams
        </a>
      </li>


    </ul>
    <ng-container *ngIf="user && user.tenant && user.tenant.name && (user.isAdmin || user.isSupport)">
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Settings</span>

      </h6>
      <ul class="nav flex-column mb-2">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/connections']" routerLinkActive="active">
            <span><fa-icon [icon]="faCode"></fa-icon></span>
            Connections
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/sync-profiles/groups']" routerLinkActive="active">
            <span><fa-icon [icon]="faUsersCog"></fa-icon></span>
            Group sync profiles
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/sync-profiles/users']" routerLinkActive="active">
            <span><fa-icon [icon]="faIdCard"></fa-icon></span>
            User sync profiles
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/schools']" routerLinkActive="active">
            <span><fa-icon [icon]="faSchool"></fa-icon></span>
            Schools
          </a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" [routerLink]="['/']">
            <span><fa-icon [icon]="faTerminal"></fa-icon></span>
            Logging
          </a>
        </li>-->
      </ul>
    </ng-container>
    <ng-container *ngIf="user && user.isTenantManager">
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Tenants</span>

      </h6>
      <ul class="nav flex-column mb-2">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/admin/tenants']" routerLinkActive="active">
            <span><fa-icon [icon]="faKey"></fa-icon></span>
            Tenants
          </a>
        </li>

      </ul>
    </ng-container>

    <footer>
      <p><a href="https://smartersoft.nl" target="_blank"><img src="/assets/smartersoft.svg" class="logo" /></a></p>
      <!--<p><small *ngIf="background?.attribution" [innerHtml]="background?.attribution"></small></p>-->
      <small><a [href]="customizations?.productLink" target="_blank">{{customizations?.name}}</a><br />v{{version}}</small>
    </footer>
  </div>
  
</nav>

