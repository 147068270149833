import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  private readonly photos: PhotoCollection = {
    archive: {
      image: '../assets/martin-adams-_OZCl4XcpRw-unsplash.jpg', attribution: 'Photo by <a href="https://unsplash.com/@martinadams?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Martin Adams</a> on <a href="https://unsplash.com/s/photos/education?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>'},
    computer: { image: '../assets/annie-spratt-vGgn0xLdy8s-unsplash.jpg', attribution: 'Photo by <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Annie Spratt</a> on <a href="https://unsplash.com/s/photos/students?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>' },
    paperpile: {
      image: '../assets/sharon-mccutcheon-tn57JI3CewI-unsplash.jpg', attribution: 'Photo by <a href="https://unsplash.com/@sharonmccutcheon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sharon McCutcheon</a> on <a href="https://unsplash.com/s/photos/documents?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>'
    },

    
  }
  constructor() { }

  public getPhoto(name: string): PhotoInfo {
    return this.photos[name] ?? this.photos['computer'];
  }
}


export interface PhotoInfo {
  attribution: string;
  image: string;
}

type PhotoCollection = {
  [key: string]: PhotoInfo;
}
