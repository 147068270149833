<header *ngIf="customizations$ | async as customizations">
  <nav class="navbar navbar-dark bg-secondary fixed-top flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0" [routerLink]="['/']">
      <img [src]="customizations.logo" class="align-middle" />
    </a>
    <a class="navbar-text nav-link col" [href]="customizations.productLink" target="_blank">{{ customizations.name }}</a>
    <ul class="navbar-nav col-sm-7 col-md-8 d-flex flex-row justify-content-end">

      <li class="nav-item"
          [routerLinkActive]="['link-active']"
          [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/home']">Dashboard</a>
      </li>
      <li class="navbar-text">{{user?.tenant?.name}}</li>
      <li ngbDropdown class="nav-item d-inline-block">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          {{user?.username || 'No user'}}
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" [routerLink]="['/profile']">Profile</a>
          <div class="dropdown-divider"></div>
          <!--<a class="dropdown-item" href="/Account/Login?promth=true">Re-authenticate</a>-->
          <!--<a class="dropdown-item" href="/Account/Logout">Sign-out</a>-->
          <a class="dropdown-item" [routerLink]="['/authentication/switch-accounts']" *ngIf="accounts?.length > 0">Switch tenant</a>
          <!--<a class="dropdown-item" [routerLink]="['/authentication/login']">Re-autenticate</a>-->
          <a class="dropdown-item" [routerLink]="['/authentication/signout']">Sign-out</a>
        </div>
      </li>
    </ul>
  </nav>
</header>
