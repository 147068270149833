import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastHttpInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.processRequest(req, next);
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequest(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req).pipe(
      tap(null, null, () => {
        if (req.method === 'PATCH') {
          this.toastr.success('Update is saved in the database', ' 💾 Updated')
        } else if (req.method === 'POST') {
          this.toastr.success('We received your item', '✅ Created')
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          console.error('🤯 %s Request to %s failed with %d', req.method, req.url, err.status)
          switch (err.status) {
            case 400:
              this.toastr.warning(`The data send to ${req.url} made our server ${this.randomError()}`, `Wrong data 💥`);
              break;
            case 401: // Do nothing, just not logged-in
              break;
            case 403:
              this.toastr.error('It seems like you aren\'t allowed to execute this action', '🔐 Forbidden');
              break;
            default:
              this.toastr.error(`🤯 ${req.method} request to ${req.url} failed ❌`, `Http Error ${err.status}`);
            
          }
        }
        throw err;
      }));
  }
  private randomErrors: string[] = ['crash', 'shake', 'cry', 'hiccup']
  private randomError(): string {
    return this.randomErrors[Math.floor(Math.random() * this.randomErrors.length)];
  }

}
