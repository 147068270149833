import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { AccountService, ApplicationService } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights?: ApplicationInsights;
  constructor(
    private router: Router,
    applicationService: ApplicationService
  ) {
    applicationService.appInsightsConfig()
      .toPromise()
      .then(config => {
        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
          config: {
            appId: `stug-frontend@${environment.appVersion}`,
            enableCorsCorrelation: true,
            instrumentationKey: config.instrumentationKey ? config.instrumentationKey : environment.appInsights.instrumentationKey,
            extensions: [angularPlugin],
            extensionConfig: {
              [angularPlugin.identifier]: { router: this.router }
            }
          }
        });
        this.appInsights.loadAppInsights();
    });

  }

  public setUserId(userId: string, accountId?: string) {
    this.appInsights?.setAuthenticatedUserContext(userId, accountId);
  }
}
