import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbDropdownModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { AccountService, ConnectionService, GroupService, UserSyncProfileService, UserService, GroupSyncProfileService, SchoolService, API_BASE_URL, ApplicationService, AccumulatedGroupService } from './services/api.services';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppRoutingModule } from './app.routing';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { FullscreenComponent } from './layouts/fullscreen/fullscreen.component';
import { IndexComponent } from './pages/index/index.component';
import { ToastHttpInterceptor } from './services/toast.http.interceptor';
import { AppInsightsService } from './services/app-insights.service';
import { CustomizationService } from './services/customization.service';
import { CommonModule } from '@angular/common';
import { PhotoService } from './services/photo.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GraphService } from './services/graph.service';

// #region MSAL
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      authority: 'https://login.microsoftonline.com/organizations',
      clientId: "f46eccda-b21f-47d3-b6e2-88b62f55d23e",
      redirectUri: '/authentication/login'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
      secureCookies: true
    },
    system: {
      loggerOptions: {
       logLevel: LogLevel.Info,
       loggerCallback: (level, message, containsPii) => {
         if (level === 3) {
           console.debug(message);
         } else {
           console.log(message);
         }
       },
       piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["User.Read"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/users/*", ["User.ReadBasic.All"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/groups/*", ["Group.Read.All"]);
  protectedResourceMap.set("/api/Application/*", null);
  protectedResourceMap.set("/api/*", ["api://c28ed055-57db-42bd-a64c-f7d063d8c697/act_as_user"]);
  //protectedResourceMap.set("https://localhost:44378/api/admin/Tenant", ["api://c28ed055-57db-42bd-a64c-f7d063d8c697/act_as_user"]);
  
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
    authRequest: (msalService, req, originalAuthRequest) => {
      return {
        ...originalAuthRequest,
        authority: `https://login.microsoftonline.com/${originalAuthRequest.account?.tenantId ?? 'organizations'}`
      }
    }
    //authRequest: {
    //  authority: 'https://login.microsoftonline.com/organizations',
    //}
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
      //extraScopesToConsent: ['api://c28ed055-57db-42bd-a64c-f7d063d8c697/act_as_user']
    },
    loginFailedRoute: "./authentication/login-failed"
  };
}

// #endregion

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FullscreenComponent,
    NavMenuComponent,
    SidebarComponent,
    IndexComponent,
  ],
  imports: [
    CommonModule, 
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    RouterModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbCollapseModule,
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ToastHttpInterceptor, multi: true },
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: MultiTenantMsalInterceptor,
    //  multi: true
    //},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    AppInsightsService, ApplicationService, CustomizationService, PhotoService,
    ConnectionService, AccumulatedGroupService, GroupService, GroupSyncProfileService,
    AccountService, SchoolService, UserSyncProfileService, UserService, FaConfig, FaIconLibrary,
    GraphService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
