import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { faCode, faTachometerAlt, faTerminal, faUsers, faUser, faKey, faIdCard, faUsersCog, faSchool } from '@fortawesome/free-solid-svg-icons'
import { Customizations, UserViewModel } from '../services/api.services';
import { environment } from '../../environments/environment';
import { PhotoInfo, PhotoService } from '../services/photo.service';
import { CustomizationService } from '../services/customization.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  host: { 'class': 'col-auto col-md-3 col-xl-2 px-sm-2 px-0'}
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() user?: UserViewModel;

  faCode = faCode;
  faKey = faKey;
  faTachometerAlt = faTachometerAlt;
  faTerminal = faTerminal;
  faUser = faUser;
  faUsers = faUsers;
  faIdCard = faIdCard;
  faUsersCog = faUsersCog;
  faSchool = faSchool;

  version = environment.appVersion;
  public background: PhotoInfo;

  public customizations: Customizations;
  private readonly _destroying$ = new Subject<void>();


 constructor(
   photoService: PhotoService,
   customizationService: CustomizationService,
   private router: Router,
   private activatedRoute: ActivatedRoute
  ) {
   this.background = photoService.getPhoto('computer');
   this.customizations = CustomizationService.Default();
   customizationService.customizations$
     .pipe(takeUntil(this._destroying$))
     .subscribe(c => {
       this.customizations = c;
     });

   this.router.events.pipe(
     takeUntil(this._destroying$),
     filter(event => event instanceof NavigationEnd),
     map((event) => {
       //console.log('Sidebar NavEnd', event, this.activatedRoute)

       const childRoute = this.activatedRoute.firstChild?.firstChild?.firstChild ?? this.activatedRoute.firstChild?.firstChild ?? this.activatedRoute.firstChild;
       //console.log('Sidebar route', childRoute);
       //if (childRoute && childRoute.snapshot.data && childRoute.snapshot.data['sidebar']) {
         
       //}
       return childRoute?.snapshot?.data['sidebar'] ?? 'computer';
     })
   ).subscribe((imageKey: string) => {
     //console.log('Changing background to', imageKey);
     this.background = photoService.getPhoto(imageKey);
   })

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
