import { Component, Input, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { Customizations, UserViewModel } from '../services/api.services';
import { CustomizationService } from '../services/customization.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  public accounts?: AccountInfo[];
  public customizations$: Observable<Customizations>;
  constructor(private msalService: MsalService, customizationService: CustomizationService) {
    this.customizations$ = customizationService.customizations$;
  }
  ngOnInit(): void {
    this.accounts = this.msalService.instance.getAllAccounts();
  }
  @Input() user?: UserViewModel;
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
