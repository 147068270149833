import { Injectable } from '@angular/core';
import { UserViewModel, AccountService } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class CachedAccountService {
  private userProfile?: UserViewModel;
  constructor(private accountService: AccountService) { }

  public async me(): Promise<UserViewModel> {
    return this.userProfile !== undefined ? this.userProfile : this.cacheUserProfile();
  }

  private async cacheUserProfile(): Promise<UserViewModel> {
    this.userProfile = await this.accountService.me().toPromise();
    return this.userProfile;
  } 
}
